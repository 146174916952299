<template>
  <MosaicUiModalsBaseModal contentClass="max-w-xl w-full" v-model="modelValue">
    <template #header>
      {{ t('marketplace.messages.sendMessage') }}
    </template>
    <template #default>
      <MosaicUiInputTextarea
        name="text"
        :error-messages="validationErrors['text']"
        v-model="formData.text"
        :disabled="isLoading"
      />
      <MosaicUiButtonsPrimaryButton
        full-rounded
        type="submit"
        class="mt-6 w-full"
        @click="onButtonClicked"
        :loading="isLoading"
        :disabled="isLoading"
      >
        {{ t('common.send') }}
      </MosaicUiButtonsPrimaryButton>
    </template>
  </MosaicUiModalsBaseModal>
</template>

<script setup lang="ts">
import {useSendPsychologistMessage} from "../composables/useSendPsychologistMessage";

const {t} = useI18n();
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  psychologistSlug: {
    type: String,
    required: true,
  }
})
const emit = defineEmits(['update:modelValue', 'sent']);
const {isLoading, formData, validationErrors, sendForm} = useSendPsychologistMessage();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value: any) {
    emit('update:modelValue', value);
  }
});

async function onButtonClicked() {
  try {
    const roomId = await sendForm(props.psychologistSlug);
    emit('sent', roomId);
    modelValue.value = false;
  } catch (e) {

  }
}
</script>

<style scoped>

</style>
