import {PsychologistMessage} from "../../../../../models/messages";
import {Ref} from "vue";
import {SendMessageUseCase} from "../../../application";
import {marketplaceTypes} from "../../../../../di/types";

export function useSendPsychologistMessage() {
  const {$container} = useNuxtApp();
  const sendMessageUseCase: SendMessageUseCase = $container.get<SendMessageUseCase>(marketplaceTypes.SendMessageUseCase);
  const {validate} = useFormValidator();

  const isLoading = ref(false);
  const formData: Ref<PsychologistMessage> = ref({
    text: null,
  });

  const validationSchema = computed(() => {
    return {
      text: ['required'],
    }
  });
  const validationErrors = ref({});

  async function sendForm(psychologistSlug: string): Promise<string> {
    validationErrors.value = {}

    try {
      await validate(validationSchema.value, formData.value);
    } catch (validationResult: any) {
      validationErrors.value = validationResult.getAllErrors();
      throw validationResult;
    }

    isLoading.value = true;
    try {
      const response = await sendMessageUseCase.send(psychologistSlug, formData.value);
      clearForm();
      return response.roomId;
    } finally {
      isLoading.value = false;
    }
  }

  function clearForm() {
    formData.value = {
      text: null,
    };
    validationErrors.value = {};
  }

  return {
    isLoading,
    formData,
    validationErrors,
    sendForm,
  }
}
