<template>
  <MosaicUiModalsBaseModal contentClass="max-w-xl w-full" v-model="modelValue">
    <template #header>
      {{ t('marketplace.messages.authTitle') }}
    </template>
    <template #default>
      <WidgetAuthMessageLogin />
<!--      <MosaicUiInputTextarea-->
<!--        name="text"-->
<!--        :error-messages="validationErrors['text']"-->
<!--        v-model="formData.text"-->
<!--        :disabled="isLoading"-->
<!--      />-->
<!--      <MosaicUiButtonsPrimaryButton-->
<!--        full-rounded-->
<!--        type="submit"-->
<!--        class="mt-6 w-full"-->
<!--        @click="onButtonClicked"-->
<!--      >-->
<!--        {{ t('common.send') }}-->
<!--      </MosaicUiButtonsPrimaryButton>-->
    </template>
  </MosaicUiModalsBaseModal>
</template>

<script lang="ts" setup>
const {t} = useI18n();

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue']);
const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value: any) {
    emit('update:modelValue', value);
  }
});
</script>

<style scoped>

</style>
