<template>
  <div v-if="catalogItem" class="space-y-4 px-4 md:px-0">
    <div class="flex flex-col items-center"
         :class="{
            'border bg-gray-50 border-meclee-blue rounded-2xl pt-4 pb-4 px-5 mt-3 mb-5 space-y-4': catalogItem.nearestTimeSlot,
            'border bg-zinc-100 border-meclee-blue rounded-2xl pt-4 pb-4 px-5 mt-3 mb-5 space-y-4': catalogItem.nearestTimeSlot && theme === 'zinc',
            'border rounded-full': catalogItem.nearestTimeSlot && !withButtons,
            'border border-rose-600 rounded-full py-4 px-6 bg-rose-50': !catalogItem.nearestTimeSlot && showNoTimeSlots,
         }"
    >
      <template v-if="catalogItem.nearestTimeSlot">
        <div class="flex flex-row items-center">
          <div class="pr-5">
            <Icon name="🔥" size="20" />
          </div>
          <div>
            <p class="text-xs whitespace-nowrap">{{ t('marketplace.catalog.nearestTimeSlot') }}</p>
            <p class="text-sm whitespace-nowrap font-medium">{{ formatSessionDateTime(catalogItem.nearestTimeSlot) }}</p>
          </div>
        </div>
        <div class="space-y-4 max-w-full" v-if="withButtons">
          <MosaicUiButtonsPrimaryButton
            v-if="isCheckoutEnabled"
            @click="emit('click')"
            class="w-full rounded-full"
            :loading="isCheckoutLoading"
            :disabled="isCheckoutLoading"
          >
            {{ t('marketplace.catalog.checkoutButton') }}
          </MosaicUiButtonsPrimaryButton>
          <slot name="sendMessageButton">
            <WidgetMarketplaceMessagesSendButton :psychologist-slug="catalogItem.id" />
          </slot>
        </div>
      </template>
      <template v-else-if="!catalogItem.nearestTimeSlot && showNoTimeSlots">
        <div class="flex flex-row items-center text-xs">
          <div class="pr-5">
            <Icon name="😔" size="18" />
          </div>
          <div>
            {{ t('marketplace.catalog.nearestTimeSlotNoFound') }}
          </div>
        </div>
      </template>
    </div>

    <slot name="sendMessageButtonWithoutSchedule" v-if="!catalogItem.nearestTimeSlot && withButtons">
      <WidgetMarketplaceMessagesSendButton
        :psychologist-slug="catalogItem.id"
      >
        <SendMessageIcon /> <span>{{ t('marketplace.messages.sendMessageWhenNotSchedule') }}</span>
      </WidgetMarketplaceMessagesSendButton>
    </slot>
  </div>
</template>

<script setup lang="ts">
import SendMessageIcon from "../../../../../assets/icons/message.svg";
import {dateFormatter} from "@meclee/time";
import {CatalogItem} from "../../../../../models/catalog";

interface Props {
  catalogItem: CatalogItem,
  isCheckoutEnabled: boolean,
  isCheckoutLoading: boolean,
  withButtons: boolean,
  showNoTimeSlots: boolean,
  theme: 'default'|'zinc',
}

const {t, locale} = useI18n();
const { formatSessionDateTime } = dateFormatter(locale.value);
const emit = defineEmits(['click']);
const props = withDefaults(defineProps<Props>(), {
  isCheckoutEnabled: true,
  isCheckoutLoading: true,
  withButtons: true,
  showNoTimeSlots: true,
  theme: 'default',
})
</script>

<style scoped>

</style>
